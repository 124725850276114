<!--税负数据案例 lh-->
<template>
    <div class="base-container">
        <div class="table-wrapper">
            <div class="search-box">
                <div class="module-title">税负数据案例</div>
            </div>
            <div class="table-box">
                <div class="input_box">
                    <p>合计：</p>
                    <p class="item">同类项目{{overview.total}}个</p>
                    <p> 税款总额区间：</p>
                    <p class="item">{{overview.minTaxation}}~{{overview.maxTaxation}} 万元</p>
                    <p> 税负比例区间：</p>
                    <p class="item">{{overview.minTaxationRate}}%~{{overview.maxTaxationRate}}%</p>
                 
                    <el-button class="search_btn" type="primary" size="small" @click="searchData(true)">自定义案例参数</el-button>
                </div>
                <el-table ref="table" :data="tableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                         @sort-change="sortChange" v-loading="loading">
                    <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                    <el-table-column sortable='custom' prop="averagePrice" label="售价（元/平方米）" align="right" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column sortable='custom' prop="totalArea" label="建设面积（平方米）" align="right"></el-table-column>
                    <el-table-column sortable='custom' prop="unitCost" label="单位成本（元/平方米）" width="190" align="right"></el-table-column>
                    <el-table-column sortable='custom' prop="totalTaxation" label="税款总额（万元）" align="right"></el-table-column>
                    <el-table-column sortable='custom' prop="taxationRate" label="税负比例" align="right">
                        <template slot-scope="scope">
                            <span>{{scope.row.taxationRate}}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="detail(scope.row)">查看税种年度详情 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <el-pagination
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next, total,jumper"
                    :total=total
                    :current-page=pageNum>
                </el-pagination>
            </div>
        </div>
        <el-dialog title="自定义参数修改" :visible.sync="showDetail" @close="closeDialog" width="740px">
            <div class="chart_box">
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question">平均售价（元/平方米）：</div>
                        <div class="feed_question">建筑面积（平方米）：</div>
                        <div class="feed_question">单位成本（元/平方米）：</div>
                    </div>
                    <div class="feed_right">
                        <el-input type="number" v-model="price"></el-input>
                        <el-input type="number" v-model="area"></el-input>
                        <el-input type="number" v-model="cost"></el-input>
                    </div>
                </div>
                <el-button class="confim_btn" type="primary" size="small" @click="next()">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="税种年度详情 " :visible.sync="showYearsDetail" @close="yearClose" width="1200px">
            <div class="chart_box">
                <div class="table_box">
                    <div class="table_header">
                        <div class="header_item w1">年份</div>
                        <div class="header_item w2">数据种类</div>
                        <div class="header_item w3">总额/总占比</div>
                        <div class="header_item w3">土地增值税</div>
                        <div class="header_item w3">企业所得税</div>
                        <div class="header_item w3">土地使用税</div>
                        <div class="header_item w3">增值税</div>
                    </div>
                    <div class="table_list" v-for="(item,index) in yearsData" :key="index">
                        <div class="year w1">
                            {{item.year}}
                        </div>
                        <div class="data">
                            <div class="bor_bot">
                                <div class="header_item w2">税款(万元)</div>
                                <div class="header_item w3">{{item.totalTax || '-'}}</div>
                                <div class="header_item w3">{{item.landAddedValueTax || '-'}}</div>
                                <div class="header_item w3">{{item.enterpriseValueTax || '-'}}</div>
                                <div class="header_item w3">{{item.landUseTax || '-'}}</div>
                                <div class="header_item w3">{{item.addedValueTax || '-'}}</div>
                            </div>
                            <div>
                                <div class="header_item w2">税负占比</div>
                                <div class="header_item w3">{{item.totalTaxRate ? (item.totalTaxRate  + '%')  : '-'}}</div>
                                <div class="header_item w3">{{item.landAddedValueTaxRate ? (item.landAddedValueTaxRate  + '%')  : '-'}}</div>
                                <div class="header_item w3">{{item.enterpriseValueTaxRate ? (item.enterpriseValueTaxRate  + '%')  : '-'}}</div>
                                <div class="header_item w3">{{item.landUseTaxRate ? (item.landUseTaxRate  + '%')  : '-'}}</div>
                                <div class="header_item w3">{{item.addedValueTaxRate ? (item.addedValueTaxRate  + '%')  : '-'}}</div>
                            </div>
                        </div>
                            
                    </div>
                    
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
export default {
    data() {
        return {
            pageNum: 1,
            pageSize: 10,
            tableHeight:'',
            listData: [],
            tableData: [],
            loading: false,
            total: 0,
            showDetail: false,
            proInfo: {},
            caseData:  {},
            price: '',
            cost: '',
            area: '',
            overview:{},
            showYearsDetail: false,
            yearsData: []
        }
    },
    created() {
        this.caseData = getStore('caseData');
        this.price = this.caseData.salePrice;
        this.area = this.caseData.area;
        this.cost = this.caseData.costUnit;
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'taxCase',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询talbe数据
        getTableData() {
            this.axios({
                method: "post", 
                url: '/dms/taxationInfo/match',
                 
                data: {
                    costUnit: this.cost,
                    salePrice: this.price,
                    area: this.area,
                },
            }).then((res) => {
                this.overview = res.data.data.overview;
                this.listData = res.data.data.detail;
                let arr = [].concat(this.listData)
                this.tableData = arr.splice((this.pageNum-1)*10,10)
                this.total = res.data.data.detail.length;
            }).catch((error) => {
                console.log(error)
            })
        },
    
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableData();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'dataCase',val);
            this.pageNum = val;
            let arr =[];
            arr = arr.concat(this.listData)
            this.tableData = arr.splice((this.pageNum-1)*10,10)
        },
        //查看详情
        detail(row) {
            this.getReport(2,'dataCase','yearDetail');
            this.axios({
                method: "post", 
                url: '/dms/taxationInfo/getTaxation',
                 
                data: {
                    projectId: this.caseData.projectId,
                    // totalIncome: row.totalIncome,
                    // projectId: '90003108',
                    // totalIncome: 6617793.26,
                },
            }).then((res) => {
                if(res.data.data.length>0){
                    this.yearsData = res.data.data;
                    this.showYearsDetail = true;
                }else{
                    this.$message.error('暂无数据');
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        //详情关闭
        yearClose(){
            this.getReport(2,'dataCase','yearClose');
        },
        searchData(){
            this.showDetail = true;
            this.getReport(2,'dataCase','customBtn');
        },
        next(){
            this.getReport(2,'dataCase','confirmBtn');
            this.axios({
                method: "put", 
                url: '/dms/taxationInfo/'+this.caseData.id,
                 
                data:{
                    id: this.caseData.id,
                    projectId: this.caseData.projectId,
                    costUnit: this.cost,
                    salePrice: this.price,
                    area: this.area,
                }
            }).then((res) => {
                if(res.data.status=="success"){
                    this.caseData = res.data.data;
                    setStore("caseData", res.data.data);
                    this.showDetail = false;
                    this.getTableData();
                    this.$message.success('更新成功！')
                }else{
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }
                
                
            }).catch((error) => {
                console.log(error)
            })
        },
        //自定义窗口关闭
        closeDialog(){
            this.getReport(2,'dataCase','customClose');
        },
        sortChange({ prop, order }) {
            this.getReport(2,'dataCase',prop+'Sort');
            this.listData.sort(this.compare(prop,order));
            this.pageNum = 1;
            let arr =[];
            arr = arr.concat(this.listData)
            this.tableData = arr.splice((this.pageNum-1)*10,10)
        },
        compare (propertyName, sort) {
            return function (obj1, obj2) {
                var value1 = obj1[propertyName]
                var value2 = obj2[propertyName]
                if (typeof value1 === 'string' && typeof value2 === 'string') {
                    const res = value1.localeCompare(value2, 'zh')
                    return sort === 'ascending' ? res : -res
                } else {
                    if (value1 <= value2) {
                        return sort === 'ascending' ? -1 : 1
                    } else if (value1 > value2) {
                        return sort === 'ascending' ? 1 : -1
                    }
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 4px;
}
.module-title {
    font-size: 16px;
    font-weight: bold;
}
.search-box {
    display: flex;
    justify-content: space-between;
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid #EBEDF0;
    padding-left: 24px;
    box-sizing: border-box;
}
.table-wrapper {
    width: 1200px;
    margin: 0 auto;
    background: #FFFFFF;
    box-sizing: border-box;
}

.table-box {
    display: block;
    overflow: hidden;
    padding: 24px;
    .el-table{
        width: 100%;
    }
}

.page-box {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    padding: 24px;

}
.input_box{
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    >p{
        float: left;
        line-height: 32px;
    }
    .item{
        margin-right: 18px;
    }
    .el-button{
        float: right;
        margin-left: 12px;
    }
    .push_chart{
        margin-left: 0 !important;
    }
    .more_search{
        color: #185BCE;
        cursor: pointer;
    }
    .right{
        float: right;
        height: 100%;
        line-height: 32px;
        color: #185BCE;
        >p{
            float: left;
            margin-right: 16px;
            cursor: pointer;
        }
        >i{
            cursor: pointer;
        }
    }
   
}
.feed_form{
    width: 400px;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 40px;
    box-sizing: border-box;
    .feed_left{
        width: 160px;
        float: left;
        text-align: right;
        .feed_question{
            height: 32px;
            line-height: 32px;
            margin-bottom: 16px;
            >i{
                color: #D40000;
            }
        }
        .feed_proposal{
            padding-top: 6px;
            height: 74.4px;
            box-sizing: border-box;
            margin-bottom: 16px;
        }
    }
    .feed_right{
        width: 214px;
        float: right;
        .value{
            height: 32px;
            line-height: 32px;
            margin-bottom: 16px;
        }
    }
    .el-input{
        height: 32px;
        margin-bottom: 16px;
        /deep/.el-input__icon{
            line-height: 32px !important;
        }
    }
    /deep/.el-input__inner{
        width: 214px;
        height: 32px !important;
        line-height: 32px !important;
    }
}
.chart_box{
    width: 100%;
    text-align: center;
    border-top: 1px solid #EBEDF0;
}
.confim_btn{
    margin: 64px 0 40px;
}
/deep/.el-dialog__body{
    padding: 0;
}
.table_box{
    height: 533px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 30px 16px;
    .table_header{
        width: 1160px;
        height: 36px;
        overflow: hidden;
        border-top: 1px solid #EBEDF0;
        border-left: 1px solid #EBEDF0;
        border-bottom: 1px solid #EBEDF0;
        background: #F6F6F6;
    }
    .header_item{
        float: left;
        line-height: 35px;
        border-right: 1px solid #EBEDF0;
        
    }
    .table_list{
        width: 1160px;
        height: 70px;
        border-left: 1px solid #EBEDF0;
        border-bottom: 1px solid #EBEDF0;
        .year{
            height: 70px;
            line-height: 70px;
            float: left;
            border-right: 1px solid #EBEDF0;
        }
        .data{
            float: left;
            .bor_bot{
                height: 35px;
                box-sizing: border-box;
                border-bottom: 1px solid #EBEDF0;
            }
        }
    }
    .w1{
        width: 55px;
        box-sizing: border-box;
        text-align: center;
    }
    .w2{
        width: 190px;
        box-sizing: border-box;
        text-align: left;
        padding-left: 12px;
    }
    .w3{
        width: 183px;
        box-sizing: border-box;
        text-align: right;
        padding-right: 12px;
    }
}
</style>